export const authEndPoints = {
   LOGIN: '/login',
   FORGOT_PASSWORD: '/forgot/password',
   VERIFY_EMAIL: '/verify/email',
   RESET_PASSWORD: '/reset/password'
}

export const adminEndPoints = {
   COMPANY: '/company',
   COMPANY_ALL: '/company/all'
}

export const companyEndPoints = {
   EMPLOYEE: '/company/employee',
   ASSIGNED_EMPLOYEE: '/company/assigned/employee',
   ROLE: '/role/company',
   ALTERNATE_CONTACT: '/company/alternate/contact',
   COMPANY: '/company',
   COMPANY_SETTING: '/company/setting',
   EMPLOYEE_STATUS: '/company/employee/status',
   EMPLOYEE_LEAD: '/user/lead',
   USERS: '/company/users',
   EMPLOYEE_MANAGER: '/user/manager',
   EMPLOYEE_ROLES: '/company/employee/roles'
}

export const userEndPoints = {
   USER: '/user',
   USER_BANK: '/user/bank',
   CHANGE_PASSWORD: "/user/change/password",
   EMERGENCY_CONTACTS: '/user/emergency/contact',
   ALTERNATE_CONTACT: '/user/alternate/contact',
   COMPANY: '/company'
}

export const commonEndPoints = {
   ROLE: '/role'
}

export const utilityEndPoints = {
   PROFILE: "/utility/upload/profile",
   LOGO: '/utility/upload/logo',
   CALENDAR: '/utility/upload/calender'
}

export const leaveEndPoints = {
   LEAVE: '/leave',
   ALL_BALANCE: '/leave/all/balance',
   USERS_BALANCE: '/leave/users/balance',
   USER_BALANCE: '/leave/user/balance',
   LEAVE_CANCEL: '/leave/cancel',
   LEAVE_INFO: '/leave/user/info',
   LEAVE_REJECT: '/leave/reject',
   LEAVE_APPROVE: '/leave/approve'
}

export const payslipEndPoints = {
   PAYSLIP: '/payout/payslip',
   PAYSLIP_ADMIN: '/payout/payslip/admin',
   PAYSLIP_USER: '/payout/payslip/user'
}

export const arrrearEndPoints = {
   ARREAR: '/payout/arrear',
   ARREAR_ADMIN: '/payout/arrear/admin',
   ARREAR_USER: '/payout/arrear/user'
}

export const bonusEndPoints = {
   BONUS: '/payout/bonus',
   BONUS_ADMIN: '/payout/bonus/admin',
   BONUS_USER: '/payout/bonus/user'
}

export const payoutEndPoints = {
   PAYOUT_ADMIN: '/payout/admin',
   PAYOUT_USER: '/payout/user'
}