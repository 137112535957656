import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    userLeaves:[],
    userLeaveInfo:{}
}

export const leaveSlice = createSlice({
	name: 'leave/userLeave',
	initialState,
	reducers: {
        setUserLeaves:(state,action)=>{
          state.userLeaves=action.payload?.reverse();
        },
        setUserLeaveInfo:(state,action)=>{
          state.userLeaveInfo=action.payload;
          state.userLeaves=action.payload?.leaveHistories?.reverse() || []
        }
	},
})

export const { setUserLeaves,setUserLeaveInfo} = leaveSlice.actions

export default leaveSlice.reducer