import { createSlice } from '@reduxjs/toolkit'

export const EmployeeSlice = createSlice({
	name: 'employees',
	initialState: {
        data:[],
        pagination:{},
        deleteConfirmation:false,
        selectedEmployee:{},
        isDialogOpen:false,
        isTable:true,
        isLoading:false,
        loading:false,
        allUsers:[]

    },
	reducers: {
        
        setEmployees:(state,action)=>{
            state.data=action.payload?.data || []
            state.pagination=action.payload?.pagination || {}
        },
        toggleDeleteConfirmation:(state,action)=>{
            state.deleteConfirmation=action.payload
        },
        setSelectedEmployee:(state,action)=>{
           state.selectedEmployee=action.payload?.data || {}
           state.isDialogOpen=action.payload?.isOpen || false
        },
        setIsTableTrue:(state)=>{
         state.isTable=true
        },
        setIsTableFalse:(state)=>{
         state.isTable=false
        },
        setIsLoading:(state,action)=>{
            state.isLoading=action?.payload || false
        },setAllUsers:(state,action)=>{
            state.allUsers=action?.payload || []
        },setLaoding:(state,action)=>{
            state.loading= action?.payload || false
        }
	},
})

export const { setEmployees ,setIsLoading,setAllUsers,setLaoding,toggleDeleteConfirmation,setSelectedEmployee,setIsTableFalse,setIsTableTrue} = EmployeeSlice.actions

export default EmployeeSlice.reducer