import axios from "axios";
import { logOut } from "utils/hooks/useAuth";

const instance = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
  timeout:30000,
	headers: {
         "Content-Type": "application/json", 
          Accept: "application/json"
    },
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
	const token = localStorage.getItem('token');
  
	 if (token)
		config.headers.Authorization = `Bearer ${token}`;

	return config;
});

// Add a response interceptor
instance.interceptors.response.use(  
    (response) => {
      const {data}=response
        return Promise.resolve(data);
      },
      (error) => {
        console.log('rororAtApiconfig',error);
        const data= error?.response?.data || {
          success:false,
          message:'Something went wrong, please try again later'
        }
        switch (error?.response?.status) {
          case 400:
            return Promise.reject(data);
          case 401:
            logOut()
            return Promise.reject(data);
          case 403:
            return Promise.reject(data);
          case 404:
            return Promise.reject(data);
          case 405:
          return Promise.reject(data);
          case 413:
              return Promise.reject({
                success:false,
                message:'the request entity is larger than limits defined by server'
              });
          case 501:
            return Promise.reject(data);
          case 500:
            return Promise.reject(data);
          case 422:
            return Promise.reject(data);
          default:
            return Promise.reject(data);
        }
      }
);

export default instance;
